import { Flex, Modal, Button } from 'antd';
import React from 'react';
import { TDeleteModalProps } from './types';

export const DeleteModal: React.FC<TDeleteModalProps> = ({ isOpen, isLoading, title, text, onClose, onFinish }) => {
  return (
    <Modal
      title={<div style={{ textAlign: 'center' }}>{title}</div>}
      open={isOpen}
      onOk={onFinish}
      onCancel={onClose}
      footer={null}
      confirmLoading={isLoading}
    >
      <div style={{ textAlign: 'center', fontSize: 16, padding: 16 }}>{text}</div>
      <Flex vertical gap={16}>
        <Button type="primary" onClick={onFinish}>
          Delete
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </Flex>
    </Modal>
  );
};
