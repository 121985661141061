import { createContext, useCallback, useContext, useMemo, useReducer, ReactElement } from 'react';
import { TVisualizationHistoryActions, TVisualizationHistoryContext } from './types';
import { reducer } from './reducer';
import { initialState } from './initialState';

const VisualizationHistoryContext = createContext<TVisualizationHistoryContext | undefined>(undefined);

export const VisualizationHistoryProvider = ({ children }: { children: ReactElement }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleAction = useCallback((data: TVisualizationHistoryActions) => {
    dispatch(data);
  }, []);

  const context = useMemo(() => ({ ...state, handleAction }), [state, handleAction]);

  return <VisualizationHistoryContext.Provider value={context}>{children}</VisualizationHistoryContext.Provider>;
};

export const useVisualizationHistory = () => {
  const context = useContext(VisualizationHistoryContext);
  if (context === undefined) {
    throw new Error('useVisualizationHistory must be used within an useVisulizationHistory');
  }
  return context;
};
