import { Button, ButtonProps } from 'antd';
import styled from 'styled-components';
import { COLORS } from 'helpers/constants';
import { SelectColorPopover } from 'components/popover';
import { forwardRef, useState } from 'react';
import { SketchPicker } from 'react-color';

type ButtonStyleProps = Omit<ButtonProps, 'color'> & {
  color?: string; 
};

const ButtonColor = styled(
  forwardRef<HTMLButtonElement, ButtonStyleProps>(({ color, ...props }, ref) => (
    <Button {...props} ref={ref} />
  ))
)<ButtonStyleProps>`
  background-color: ${(props) => props.color ?? '#C3C3C3'};
  padding: 7px 16px;
  text-align: start;

  span {
    color: ${COLORS.PRIMARY.GRAY_DARK};
  }

  &&&:hover {
    background-color: ${(props) => props.color ?? '#4f5988'};
    > span {
      color: #ffffff;
    }
  }
`;

type Props = {
  initialColor?: string;
  fieldName?: string | number;
  setValue: (color: string) => void;
};

export const ColorSelect = ({ initialColor, setValue }: Props) => {
  const [color, setColor] = useState(initialColor || '#DDDDDD');

  const handleOnChange = (color: string) => {
    setValue(color);
    setColor(color);
  };

  return (
    <SelectColorPopover
      content={
        <SketchPicker
          color={color}
          onChange={(color) => handleOnChange(color?.hex as string)}
        />
      }
      overlayStyle={{ width: 220 }}
    >
      <ButtonColor block color={color}>
        Select color
      </ButtonColor>
    </SelectColorPopover>
  );
};
