import { Button } from 'antd';
import styled from 'styled-components';

export const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 24px 44px 16px;
  margin-top: auto;
`;

export const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3px;
  margin-bottom: 16px;
  width: 100%;

  & button {
    font-size: 20px;
    width: 100%;
    font-weight: 600;
    border-radius: 4px;
    letter-spacing: 1.4px;
    height: 40px;
  }

  & button[name='clear-all-visualisation'] {
    border: 1px solid #414141;
    color: #414141;
    background: transparent;
    &:hover {
      background: rgba(0, 0, 0, 0.06);
    }
  }

  & button[name='add-btn-visualisation'] {
    border: 1px solid #fff;
    color: #232f6a;
    background: #ced2de;
  }

  & button[name='run-btn-visualisation'] {
    color: #fff;
    background: #232f6a;
    &:hover {
      color: #fff;
      background: #001479;
    }
  }
`;

export const StyledAddButton = styled(Button)`
  width: 100%;
  font-weight: 600;
  border-radius: 4px;
  border: 1px solid #fff;
  color: #232f6a;
  background: #ced2de;
  margin-bottom: 16px;
`;
