import { Flex } from 'antd';
import { COLORS } from 'helpers/constants';
import styled, { css } from 'styled-components';

export const ButtonStyles = css`
  display: flex;
  align-items: center;
  color: #232f6a;
  height: 3rem;
  font-weight: 600;
  letter-spacing: 1.4px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%);
  box-shadow: 0 10px 10px 0 rgba(141, 143, 166, 0.2);
  backdrop-filter: blur(7px);
`;

export const LayoutWrapper = styled(Flex)<{ isDocument?: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  width: 65px;
  height: 100%;
  align-items: center;
  justify-content: start;
  padding: 8px 0;
  overflow-y: auto;
  background-color: ${COLORS.MAIN_GRAY};
  box-shadow: -2px 0px 2px ${COLORS.MAIN_GRAY_SILVER};

  & svg {
    cursor: pointer;
  }
`;

export const ControlsWrapper = styled.div`
  position: absolute;
  width: 'max-content';
  top: 0;
  right: 75px;
  display: flex;
  gap: 0.5rem;
  padding: 8px 0;

  button {
    ${ButtonStyles}
  }

  .reset {
    color: white;
    background: #232f6a;
    font-size: 16px;

    .reset-icon {
      width: 16px;
      height: 16px;
    }

    &:hover {
      color: #232f6a !important;

      .reset-icon {
        stroke: #232f6a;
      }
    }
  }
`;
