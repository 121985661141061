import { FC } from 'react';
import { Button, Flex } from 'antd';
import styled from 'styled-components';
import { SearchUsers } from './search-users';
import { CloseOutlined } from '@ant-design/icons';
import { Text } from 'components/typography';

interface DrawerTitleProps {
  onClose: () => void;
  search: string | undefined;
  setSearch: (value: string) => void;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  .stretch {
    border-radius: 4px;
    border: 1px solid #fff;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.5) 100%);
    box-shadow: 0px 10px 10px 0px rgba(141, 143, 166, 0.2);
    backdrop-filter: blur(7px);
  }
`;

export const DrawerTitle: FC<DrawerTitleProps> = ({ onClose, setSearch, search }) => {
  return (
    <Wrapper>
      <Flex gap={10} align='center'>
        <Button
          style={{ display: 'flex', alignItems: 'center', background: 'transparent' }}
          icon={<CloseOutlined />}
          type="text"
          onClick={onClose}
        />
        <Text>Members list</Text>
      </Flex>
      <div>
        <SearchUsers setSearch={setSearch} search={search} />
      </div>
    </Wrapper>
  );
};
