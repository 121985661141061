import { Form, Popover } from 'antd';
import { VerticalSpace } from 'components/space/vertical-space';
import { TabTablesQueries } from 'pages/data-sheet/components/all-data-queries/tab-tables-queries';
import { PropertySection } from './property-section';
import { useCallback } from 'react';

type Props = {
  openTable: boolean;
  setOpenTable: (openTable: boolean) => void;
  clearFilter?: () => void;
  removeItemQueries?: (field: number) => void;
  isVisualisation?: boolean;
};

export const QueriesForm = ({
  openTable,
  setOpenTable,
  isVisualisation = false,
  clearFilter,
  removeItemQueries,
}: Props) => {
  const handleOpenChange = useCallback(
    (newOpen: boolean) => {
      setOpenTable(newOpen);
    },
    [setOpenTable]
  );

  const popoverStyle = isVisualisation ? { width: '460px', height: '500px' } : { width: '90%', height: '50%' };

  return (
    <Form.List name="queries">
      {(fields, { add, remove }) => {
        return (
          <VerticalSpace>
            {fields.map((field) => {
              return (
                <PropertySection
                  key={field.key}
                  remove={() => {
                    remove(field.name);
                    removeItemQueries?.(field.name);
                    clearFilter?.();
                  }}
                  fieldName={field.name}
                  isVisualisation={isVisualisation}
                />
              );
            })}
            <Popover
              arrow={false}
              autoAdjustOverflow
              placement="top"
              trigger="click"
              open={openTable}
              onOpenChange={handleOpenChange}
              getPopupContainer={() => document.getElementById('queries-form-body') as HTMLElement}
              overlayInnerStyle={{ width: '90%', maxHeight: '500px', overflow: 'auto' }}
              overlayStyle={popoverStyle}
              content={
                <TabTablesQueries
                  setOpenTable={setOpenTable}
                  add={add}
                  fieldsLength={fields.length || 0}
                  isVisualisation={isVisualisation}
                />
              }
            >
              <div />
            </Popover>
          </VerticalSpace>
        );
      }}
    </Form.List>
  );
};
