import { List, Modal, Upload, UploadProps, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { TMainModalProps } from './types';
import { InboxOutlined } from '@ant-design/icons';

const { Dragger } = Upload;
const { Title } = Typography;

export const QrCodeReader: React.FC<TMainModalProps> = ({ isOpen, onClose }) => {
  const [responseData, setResponseData] = useState<string[] | null>(null);

  useEffect(() => setResponseData(null), [isOpen]);

  const props: UploadProps = {
    name: 'file',
    multiple: false,
    action: `${process.env['REACT_APP_BASE_URL']}/bedrock`,
    onChange(info) {
      const { status, response } = info.file;
      if (status === 'done') {
        setResponseData(response.data);
      }
    },
  };

  return (
    <Modal style={{ top: 20 }} open={isOpen} onCancel={onClose} footer={null} width={'50vw'} maskClosable={true}>
      <div style={{ padding: '20px' }}>
        {responseData ? (
          <List
            header={<Title level={5}>Results</Title>}
            dataSource={responseData}
            renderItem={(item, _i) => <List.Item key={_i}>{item}</List.Item>}
          />
        ) : (
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
          </Dragger>
        )}
      </div>
    </Modal>
  );
};
