import styled from 'styled-components';
import { COLORS } from 'helpers/constants';

export const VisualizationHistoryContainer = styled.div`
  max-width: 320px;
  width: 100%;
  position: absolute;
  top: 10px;
  right: 75px;

  & .ant-select-single {
    width: 100%;
  }

  & .ant-select-selector {
    border: 1px solid ${COLORS.PRIMARY.BLUE} !important;
  }
`;
