import { Modal, Form, Flex, Button } from 'antd';
import { Text } from 'components/typography';
import React, { useEffect } from 'react';
import { TSaveByTitleModalProps, TSaveVisualizationWithTitleData } from './types';
import { Input } from 'components/input';

export const SaveByTitleModal: React.FC<TSaveByTitleModalProps> = ({
  label,
  name,
  onFinish,
  onClose,
  isOpen,
  title,
  isLoading,
}) => {
  const [form] = Form.useForm();

  const onHandleFinish = (values: TSaveVisualizationWithTitleData) => {
    onFinish(values);
    form.resetFields();
  };

  useEffect(() => {
    form.setFieldsValue({ title: name });
  }, [form, name]);

  return (
    <Modal open={isOpen} title={title} footer={false} onCancel={onClose}>
      <Text>{label}</Text>
      <Form layout="vertical" form={form} onFinish={onHandleFinish}>
        <Form.Item
          name="title"
          rules={[
            { required: true, message: 'Title is required' },
            { min: 3, message: 'Title must be at least 3 characters' },
            { max: 80, message: 'Title cannot exceed 80 characters' },
          ]}
        >
          <Input />
        </Form.Item>
        <Flex vertical gap={16}>
          <Button htmlType="submit" type="primary" disabled={isLoading}>
            Save
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </Flex>
      </Form>
    </Modal>
  );
};
