import React, { useCallback, useEffect } from 'react';
import { Dropdown, Tooltip } from 'antd';
import { LayoutWrapper } from './style';
import { LayoutConfig, LayoutType, ProcessAndRenderArgs } from './types';
import { UserProjectRole } from 'api/types';
import { gpuEnabled, createCombos, graphRender } from 'components/layouts/components/visualisation/helpers/utils';
import { useGraph } from 'components/layouts/components/visualisation/wrapper';
import { formattedData } from 'components/layouts/components/visualisation/helpers/format-node';
import { initData } from 'components/layouts/components/visualisation/container/initial/nodes';
import { radialMenuItems } from './items';
import { VisualisatoinTools } from './constat';
import { NODE_LIMIT } from 'helpers/constants';
import { useProject } from 'context/project-context';
import { useGetData } from 'api/visualisation/use-get-data';
import { ReactComponent as ComboSvg } from './icons/visualisation-combo-action.svg';
import { ReactComponent as RadialSvg } from './icons/visualisation-radial-action.svg';
import { ReactComponent as FazziMatchSvg } from './icons/visualisation-fuzzy-match-action.svg';
import { ReactComponent as ResetSvg } from './icons/visualisation-reset-action.svg';
import { ReactComponent as SaveAsSvg } from './icons/visualisation-save-as-action.svg';
import { useGetAllVisualizationById } from 'api/visualization-history/use-get-visualization-by-id';
import { VisualizationHistory } from '../visualisation-history';
import { useVisualizationHistory } from 'context/visualization-history';
import { ACTIONS } from 'context/visualization-history/reducer';

const { COMBO, RADIAL, FAZZY_MATCH, RESET, SAVE_AS } = VisualisatoinTools;

export const Settings: React.FC<{ isDocument?: boolean }> = ({ isDocument }) => {
  const { visualizationId, handleAction, reset } = useVisualizationHistory();

  const { graph, startFuzzyModal, setGraphInfo } = useGraph() || {};

  const { projectInfo } = useProject();

  const { nodes, edges, count, relationsCounts } = useGetData({
    enabled: reset,
  });

  const { data: savedData } = useGetAllVisualizationById(visualizationId, {
    enabled: !!visualizationId,
  });

  const onHandleToggleModal = useCallback(() => {
    handleAction({
      type: ACTIONS.OPEN_SAVE_MODAL,
      payload: {},
    });
  }, [handleAction]);

  const onHandleReset = useCallback(() => {
    handleAction({
      type: ACTIONS.RESET_VISUALIZATION,
      payload: {},
    });
  }, [handleAction]);

  const setLayout = useCallback(
    (layout: LayoutType) => {
      if (graph) {
        graph.destroyLayout();

        const params = { type: layout };
        const layoutConfig: LayoutConfig = {
          concentric: {
            maxLevelDiff: 0.5,
            sortBy: 'topology',
            edgeLength: 10,
            preventOverlap: true,
            nodeSize: 20,
          },
          grid: {
            begin: [0 - window.innerWidth / 2, 148 - window.innerHeight / 2],
            preventOverlap: true,
            preventOverlapPadding: 20,
            nodeSize: 20,
            condense: false,
            rows: 5,
            cols: 5,
            sortBy: 'topology',
            workerEnabled: true,
          },
          circular: {
            ordering: 'topology',
            radius: graph.getNodes().length * 10,
          },
          radial: {
            type: 'gForce',
            center: [window.innerWidth / 2, window.innerHeight / 2],
            linkDistance: 100,
            nodeStrength: 600,
            edgeStrength: 200,
            nodeSize: 20,
            workerEnabled: true,
            gpuEnabled: gpuEnabled,
          },
        };

        const [x, y] = [window.innerWidth / 2, window.innerHeight / 2];

        graph.updateLayout({ ...params, ...layoutConfig[layout] }, 'center', { x, y }, true);

        setTimeout(() => {
          graph.fitCenter(true);
          graph.fitView([x, y], { onlyOutOfViewPort: true, direction: 'both', ratioRule: 'max' }, true);
        }, 1500);
      }
    },
    [graph]
  );

  const processAndRender = useCallback(
    async ({ nodeList, edgeList, relationsCounts, savedGraph, nodeCountValue }: ProcessAndRenderArgs) => {
      const data = formattedData(nodeList, edgeList, relationsCounts, savedGraph);
      setGraphInfo({
        nodeCount: Math.min(nodeCountValue, NODE_LIMIT),
      });
      if (data) {
        initData(graph, data);
        graphRender(graph, !!visualizationId);
      }
    },
    [graph, setGraphInfo, visualizationId]
  );

  useEffect(() => {
    if (savedData?.data?.count) {
      const { nodes, edges, relationsCounts } = savedData.data;
      processAndRender({
        nodeList: nodes,
        edgeList: edges,
        relationsCounts,
        savedGraph: savedData.savedGraph,
        nodeCountValue: savedData.data.count,
      });
    } else if (reset) {
      processAndRender({
        nodeList: nodes ?? [],
        edgeList: edges ?? [],
        relationsCounts: relationsCounts ?? {},
        savedGraph: undefined,
        nodeCountValue: count ?? 0,
      });
    }
  }, [savedData, graph, reset, nodes, edges, relationsCounts, visualizationId, setGraphInfo, count, processAndRender]);

  return (
    <>
      <VisualizationHistory />
      <LayoutWrapper vertical gap={16} isDocument={isDocument}>
        <Tooltip title={RESET}>
          <ResetSvg onClick={onHandleReset} />
        </Tooltip>
        <Dropdown overlay={radialMenuItems({ setLayout })} placement="bottomLeft" trigger={['click']}>
          <Tooltip title={RADIAL} placement="left">
            <RadialSvg />
          </Tooltip>
        </Dropdown>
        <Tooltip title={COMBO} placement="left">
          <ComboSvg onClick={() => createCombos(graph)} />
        </Tooltip>
        {projectInfo?.role === UserProjectRole.Owner && (
          <Tooltip title={FAZZY_MATCH} placement="left">
            <FazziMatchSvg onClick={() => startFuzzyModal({ isOpened: true })} />
          </Tooltip>
        )}
        <Tooltip title={SAVE_AS}>
          <SaveAsSvg onClick={onHandleToggleModal} />
        </Tooltip>
      </LayoutWrapper>
    </>
  );
};
