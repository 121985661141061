import { TInitialState, TVisualizationHistoryActions } from './types';

export const ACTIONS = {
  SELECT_HISTORY: 'SELECT_HISTORY',
  OPEN_SAVE_MODAL: 'OPEN_SAVE_MODAL',
  RESET_VISUALIZATION: 'RESET_VISUALIZATION',
  SET_HOVER_ITEM: 'SET_HOVER_ITEM',
  SET_STYLES_DATA: 'SET_STYLES_DATA',
  REMOVE_QUERY_ITEM: 'REMOVE_QUERY_ITEM',
  CREATE_NEW_VISUALIZATION: 'CREATE_NEW_VISUALIZATION',
} as const;

export const reducer = (state: TInitialState, action: TVisualizationHistoryActions) => {
  const { type, payload } = action;
  switch (type) {
    /**
     * @description select visualization
     * @param [id, name]
     * @returns state
     */

    case ACTIONS.SELECT_HISTORY: {
      const isMain = payload.id === 'main';
      return {
        ...state,
        visualizationId: isMain ? undefined : payload.id,
        name: isMain ? undefined : payload.name,
        reset: isMain,
        queriesStyle: undefined,
      };
    }

    /**
     * @description toggle modal create new visualization
     * @param none
     * @returns state
     */

    case ACTIONS.OPEN_SAVE_MODAL: {
      return {
        ...state,
        openSaveModal: !state.openSaveModal,
      };
    }

    /**
     * @description reset visualization
     * @params none
     * @returns state
     */

    case ACTIONS.RESET_VISUALIZATION: {
      return {
        ...state,
        name: undefined,
        visualizationId: undefined,
        openSaveModal: false,
        reset: !state.reset,
        queriesStyle: undefined,
      };
    }

    /**
     * @description  set hover item
     * @params none
     * @returns state
     */

    case ACTIONS.SET_HOVER_ITEM: {
      return {
        ...state,
        hoverItem: payload.id,
      };
    }

    /**
     * @description set queryArr in styles left bar when click run
     * @params [array] styles array values.query
     * @type Array
     * @returns state
     */

    case ACTIONS.SET_STYLES_DATA: {
      return {
        ...state,
        queriesStyle: payload,
      };
    }

    /**
     * @description when click new button visualization
     * @params [array]
     * @type Array
     * @returns state
     */

    case ACTIONS.CREATE_NEW_VISUALIZATION: {
      return {
        ...state,
        visualizationId: undefined,
        name: undefined,
      };
    }

    /**
     * @description when click x button remove item current
     * @params [filed] number
     * @returns state
     */

    case ACTIONS.REMOVE_QUERY_ITEM: {
      let queryStyles = state.queriesStyle;
      if (state.queriesStyle) {
        queryStyles = state.queriesStyle.filter((_, index) => index !== payload);
      }
      return {
        ...state,
        queryStyles,
      };
    }

    default:
      return state;
  }
};
