import { TStylesItem } from 'api/types';
import { QueryFilterTypes } from 'components/select/queries-select';
import { COLORS } from 'helpers/constants';
import { TStyleQueries, TStyleQueriesItem } from './type';
import G6, { Graph } from '@antv/g6';

const initialSize = 40;

enum GraphType {
  IMAGE = 'image',
  CIRCLE = 'circle',
}

/**
 * @name formmatedDataStyles
 * @description convert data to left bar styles form format and set fields
 * @param data
 * @returns object
 */

export const formmatedDataStyles = (data: TStylesItem) => {
  const formData = data?.queryArr?.map((item) => {
    if (item.type === 'node') {
      let action = QueryFilterTypes.IS_NOT_NULL as string;
      if (item.query.length) {
        action = item?.query[0].action.replace('_VALUE', '');
        action = action.charAt(0).toUpperCase() + action.slice(1).toLowerCase();
        return {
          ...item.query[0],
          default_property: false,
          id: item.query[0].property_type_id,
          key: item.query[0].property_type_id,
          value: item.query[0].property_type_id,
          parent_id: item.project_type_id,
          depth: 2,
          label: item.query[0].value,
          labelName: item.label + '.' + item.query[0].name,
          labelValue: item.label + '.' + item.query[0].name,
          ref_property_type_id: item.query[0].type,
          type: action,
          typeText: item.query[0].value,
          color: item.styling.color,
          size: item.styling.size,
          icon: item.styling.icon,
        };
      }
      return {
        ...item.query[0],
        default_property: false,
        id: item.project_type_id,
        key: item.project_type_id,
        value: item.project_type_id,
        parent_id: item.project_type_id,
        depth: 1,
        label: item.label,
        labelName: item.label,
        labelValue: item.label,
        type: action,
        color: item.styling.color,
        size: item.styling.size,
        icon: item.styling.icon,
      };
    } else {
      return {
        depth: 1,
        id: item.project_type_id,
        isConnectionType: true,
        key: item.label,
        value: item.label,
        label: item.label,
        labelName: item.label,
        color: item.styling.color,
        action: item.action,
        borderSize: item.styling.size,
        borderType: item.styling.type,
        borderDashed: item.styling.type === 'dashed',
      };
    }
  });
  return formData;
};

/**
 * @name updateStylesNodeEdges
 * @description how can transfer data query style and auto update
 * @return styles object node
 */

export const updateStylesNodeEdges = (data: TStyleQueriesItem, nodeImage?: string, nodeColor?: string) => {
  const { size, color, icon } = data;
  const defaultParams = {
    size: size || initialSize,
    style: {
      stroke: color || nodeColor,
      fill: icon || !nodeImage ? COLORS.PRIMARY.WHITE : 'transparent',
      lineWidth: 2,
      width: size || initialSize,
      height: size || initialSize,
    },
  };

  return {
    type: icon ? GraphType.CIRCLE : nodeImage ? GraphType.IMAGE : GraphType.CIRCLE,
    color: color || nodeColor,
    icon: icon && {
      show: true,
      width: size,
      height: size,
      img: icon || '',
    },
    img: nodeImage ? nodeImage : undefined,
    clipCfg: nodeImage &&
      !icon && {
        show: true,
        type: GraphType.CIRCLE,
        width: size,
        height: size,
        r: size / 2,
      },
    ...defaultParams,
  };
};

/**
 * @name removeStylesItem
 * @description remove item style
 * @param [queriesStyle, graph, field]
 * @type TStyleQueries, Graph, number
 * @return void
 */

export const removeStylesItem = (queriesStyle: TStyleQueries | undefined, graph: Graph, field: number) => {
  if (queriesStyle?.[field]?.isConnectionType) {
    const edges = graph.getEdges().filter((edge) => edge.getModel().project_edge_type_id === queriesStyle?.[field].id);
    edges.forEach((edge) => {
      graph.update(edge, {
        style: {
          stroke: COLORS.MAIN_GRAY_SILVER,
          lineWidth: 2,
        },
      });
      graph.refreshItem(edge);
    });
  } else {
    const query_id = queriesStyle?.[field]?.parent_id ? queriesStyle[field]?.parent_id : queriesStyle?.[field]?.id;
    const filterNodes = graph.getNodes()?.filter((item) => item.getModel().nodeType === query_id);
    filterNodes.forEach((node) => {
      const model = node.getModel() as { img: string; color: string };
      const { img, color } = model;
      graph.updateItem(node.getID(), {
        size: 40,
        color,
        icon: {
          show: false,
          img: img ? img : '',
        },
        type: img ? GraphType.IMAGE : GraphType.CIRCLE,
        style: {
          stroke: color as string,
          fill: img ? 'transparent' : COLORS.PRIMARY.WHITE,
          width: initialSize,
          height: initialSize,
        },
        clipCfg: img && {
          show: true,
          type: GraphType.CIRCLE,
          width: initialSize,
          height: initialSize,
          r: initialSize / 2,
        },
      });
      graph.refreshItem(node);
    });
  }
};

/**
 * @name resetAllStylesNodeAndEdges
 * @description remove items styles all node && edges
 * @param graph
 * @type Graph
 * @return void
 */

export const resetAllStylesNodeAndEdges = (graph: Graph) => {
  graph.getNodes().forEach((node) => {
    const model = node.getModel() as { img: string; color: string };
    const { img, color } = model;
    graph.updateItem(node.getID(), {
      size: 40,
      color,
      icon: {
        show: false,
        img: img ? img : '',
      },
      type: img ? GraphType.IMAGE : GraphType.CIRCLE,
      style: {
        stroke: color as string,
        fill: img ? 'transparent' : COLORS.PRIMARY.WHITE,
        width: initialSize,
        height: initialSize,
      },
      clipCfg: img && {
        show: true,
        type: GraphType.CIRCLE,
        width: initialSize,
        height: initialSize,
        r: initialSize / 2,
      },
    });
    graph.refreshItem(node);
  });

  graph.getEdges().forEach((edge) => {
    graph.updateItem(edge.getID() as string, {
      style: {
        stroke: COLORS.MAIN_GRAY_SILVER,
        lineWidth: 2,
        lineDash: [],
        endArrow: {
          fill: COLORS.MAIN_GRAY_SILVER,
          path: G6.Arrow.triangle(10, 15, 5),
          d: 5,
        },
      },
    });
    graph.refreshItem(edge);
  });
};
