import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { URL_SAVED_VISUALIZATION } from './constant';
import client from 'api/client';
import { TStylesData } from 'api/types';

export type TUseAllStyles = (options?: UseQueryOptions<{ data: TStylesData }, Error>) => {
  data: TStylesData | undefined;
  isLoading: boolean;
  isSuccess: boolean;
};

export const useGetAllVisualizationHistory: TUseAllStyles = () => {
  const { id } = useParams();

  const URL = URL_SAVED_VISUALIZATION.replace(':project_id', id ?? '');

  const result = useQuery<{ data: TStylesData }>({
    queryKey: [URL],
    queryFn: () => client.get(URL),
  });

  const { isLoading, data, isSuccess } = result;

  return {
    isLoading,
    data: isSuccess ? data?.data : undefined,
    isSuccess,
  };
};
