import styled from 'styled-components';
import { Search } from './search';
import { useGraph } from 'components/layouts/components/visualisation/wrapper';

const ToolStyle = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 8px;
  top: 176px;
  z-index: 1;
  transition: left 0.5s ease-in-out;

  > * {
    width: 40px;
    height: 40px;
    box-shadow: 0px 10px 10px rgba(141, 143, 166, 0.2);
  }

  .add-type,
  .add-link {
    cursor: pointer;

    &:hover {
      rect:first-child {
        fill: rgba(35, 47, 106, 0.8);
      }
    }
  }
`;

export const SearchData = () => {
  const { graphInfo} = useGraph() ?? {};
  return (
    <>
      <ToolStyle style={{ left: graphInfo?.collapsedWidth ? `${graphInfo.collapsedWidth + 30}px`:`30px` }}>
        <Search collapsed={graphInfo?.collapsedWidth} />
      </ToolStyle>
    </>
  );
};
