import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import client from 'api/client';
import { URL_SAVED_VISUALIZATION } from './constant';
import { TSaveDataStyles } from 'pages/project-visualisation/components/visualisation-history/types';

export const useSaveVisualization = () => {
  const { id } = useParams();

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (data: TSaveDataStyles) => {
      return client.post(URL_SAVED_VISUALIZATION.replace(':project_id', id ?? ''), {
        ...data,
      });
    },

    onSuccess: () => {
      queryClient.invalidateQueries([URL_SAVED_VISUALIZATION.replace(':project_id', id ?? '')]);
    },
  });

  return mutation;
};
