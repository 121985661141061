import { Badge, Typography, Flex } from 'antd';
import { StyledAvatar } from './styles';
import { ReactComponent as Araks } from '../../icons/araks-tiny.svg';

const { Paragraph } = Typography;

export const renderProjectsGroup = (id: string, title: string) => {
  return {
    key: `project${id}`,
    id,
    mode: 'Projects',
    value: id,
    title,
    label: (
      <Flex align="center" gap={20}>
        <Badge>
          <StyledAvatar color={'inherit'} shape="square" size="small" icon={<Araks />} />
        </Badge>
        <Paragraph strong ellipsis={{ rows: 1 }} style={{ fontSize: '16px', marginBottom: 0 }}>
          {title}
        </Paragraph>
      </Flex>
    ),
  };
};
