import { useMutation, useQueryClient } from '@tanstack/react-query';
import client from 'api/client';
import { URL_DELETE_GROUP_MEMBER, URL_GET_ALL_MEMBERS_GROUP } from './constant';
import { useParams } from 'react-router-dom';
import { message } from 'antd';

export const useDeleteMemberGroup = () => {
  const params = useParams();
  const url = window.location.href;
  const paramsd = new URL(url).searchParams;
  const idMamber = paramsd.get('idMember') || null;
  const IdUser = idMamber ? idMamber : params.id;

  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (id: string) => {
      return client.delete(URL_DELETE_GROUP_MEMBER.replace(':group_id', IdUser ?? '').replace(':member_id', id));
    },
    onSuccess: () => {
      queryClient.invalidateQueries([URL_GET_ALL_MEMBERS_GROUP]);
      const currentUrl = window.location.href;
      const newUrl = currentUrl.split('?')[0];
      window.history.pushState({}, '', newUrl);
      message.success('Member deleted successfully');
    },
  });
  return mutation;
};
