import { Flex } from 'antd';
import styled from 'styled-components';

export const ToolbarWrapper = styled(Flex)<{ isSchema?: boolean }>`
  & {
    width: 'max-content';
    align-items: center;
    justify-content: center;
    position: fixed;
    right: ${(props) => (props.isSchema ? 10 : 75)}px;
    bottom: 10px;
    padding: 10px;
    background: linear-gradient(135deg, rgba(255, 255, 255) 0%, rgba(255, 255, 255) 100%);
    border-radius: 4px;
  }
`;
