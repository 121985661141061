import React from 'react';
import { Tooltip } from 'antd';
import { ReactComponent as FitSVG } from '../icons/fit.svg';

export const FitSchema: React.FC<{ onCenterContent: VoidFunction }> = ({ onCenterContent }) => {
  return (
    <Tooltip key={2} placement="top" title="Fit Schema">
      <FitSVG onClick={onCenterContent} />
    </Tooltip>
  );
};
