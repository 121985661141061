import { useGetAdminAnalysesDashboard } from 'api/admin/use-get-analyses-dashboard';
import { COLORS } from 'helpers/constants';
import styled from 'styled-components';
import { Card, Select } from 'antd';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useState } from 'react';
import { getYearsArrFrom } from 'helpers/utils';
import { CustomSelect } from '../Styled';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 800px;
  color: '#F6976D';
  background-color: ${COLORS.PRIMARY.WHITE};
  margin-bottom: 40px;
  gap: 40px;
  border-radius: 8px;
  padding: 20px;
  outline: none;
  box-shadow: 0px 10px 20px 5px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
`;

export const AdminUsersAnalyseCount = () => {
  const yearArray = getYearsArrFrom({ from: 2023, to: undefined });
  const [selectedYear, setSelectedYear] = useState<number | undefined>(undefined);
  const { data, isSuccess } = useGetAdminAnalysesDashboard({ type: 'users', year: selectedYear });

  const chartData = isSuccess
    ? data.data.map((item) => ({
        month: `${item.year},${item.month}`,
        count: +item.count,
      }))
    : [];

  const handleYearChange = (year: number) => {
    if (year === 0) {
      setSelectedYear(undefined);
    } else setSelectedYear(year);
  };

  return (
    <Container>
      <Card
        style={{ maxWidth: '95%', minWidth: '80%' }}
        title="Count of Users"
        extra={
          <CustomSelect defaultValue={0} onChange={(e) => handleYearChange(e as number)}>
            <Select.Option value={0}>All Years</Select.Option>
            {yearArray.map((item, index) => (
              <Select.Option key={index} value={item}>
                {item}
              </Select.Option>
            ))}
          </CustomSelect>
        }
      >
        <ResponsiveContainer width="100%" height={500}>
          <BarChart data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" label={{ value: 'Month of the Year', position: 'insideBottom', offset: -10 }} />
            <YAxis label={{ value: 'Count of Users', angle: -90, position: 'insideLeft' }} />
            <Tooltip />
            <Legend />
            <Bar dataKey="count" fill="#F5B452DE" barSize={30} />
          </BarChart>
        </ResponsiveContainer>
      </Card>
    </Container>
  );
};
