import styled from 'styled-components';
import { Button } from 'antd';
import { COLORS } from 'helpers/constants';

const { PRIMARY, BLUE_10 } = COLORS;

export const StyledButton = styled(Button)`
  border: none;
  background-color: transparent !important;

  &&:hover {
    background: ${BLUE_10} !important;

    .anticon {
      color: ${PRIMARY.WHITE};
    }
  }
`;
