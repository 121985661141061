import { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from 'antd';
import { useGraph } from 'components/layouts/components/visualisation/wrapper';
import { QueriesForm } from 'components/form/all-data/queries-form';
import { Buttons } from '../components/buttons';
import { StyledMainWrapper } from './styles';
import G6 from '@antv/g6';
import { QueryType } from './enums';
import { Props } from './type';
import { useParams } from 'react-router-dom';
import { useVisualizationHistory } from 'context/visualization-history';
import { ACTIONS } from 'context/visualization-history/reducer';
import { useGetVisualizationStyles } from 'api/visualization-history/use-get-visualization-styles';
import { formmatedDataStyles, removeStylesItem, resetAllStylesNodeAndEdges, updateStylesNodeEdges } from './utiles';

export const Styling = () => {
  const [openTable, setOpenTable] = useState(false);

  const { graph } = useGraph() ?? {};
  const [form] = Form.useForm();
  const { id: project_id } = useParams();

  const borderInitSize = 6;

  const isReset = useRef(false);

  const { visualizationId, queriesStyle, handleAction } = useVisualizationHistory();

  const { data } = useGetVisualizationStyles(visualizationId, {
    enabled: !!visualizationId,
  });

  const onHandleToggleModal = useCallback(() => {
    handleAction({
      type: ACTIONS.OPEN_SAVE_MODAL,
      payload: {},
    });
  }, [handleAction]);

  const removeItemQueries = useCallback(
    (field: number) => {
      removeStylesItem(queriesStyle, graph, field);
      handleAction({
        type: ACTIONS.REMOVE_QUERY_ITEM,
        payload: field,
      });
      form.submit();
      graph.render();
    },
    [form, graph, handleAction, queriesStyle]
  );

  const removeGraphStyle = () => {
    resetAllStylesNodeAndEdges(graph);
    form.resetFields();
    graph.render();
  };

  const onFinish = useCallback(
    (values: Props) => {
      if (!project_id) return;

      handleAction({
        type: ACTIONS.SET_STYLES_DATA,
        payload: values.queries,
      });

      if (values.queries) {
        graph.getNodes().forEach((node) => {
          graph.updateItem(node.getID(), {
            size: 40,
            style: {
              stroke: node.getModel()?.color as string,
              fill: node.getModel()?.img ? 'transparent' : 'white',
            },
          });
        });

        values.queries.forEach((query) => {
          if (query.parent_id && query.depth === 2) {
            const typeIsNot = query.type === QueryType.IsNot;
            const typeIsNotNull = query.type === QueryType.IsNotNull;
            const typeIs = query.type === QueryType.Is;
            const typeIsNull = query.type === QueryType.IsNull;
            const typeText = query.typeText ? query.typeText.toLowerCase() : '';

            const filteredNodes = graph.getNodes().filter((node) => {
              const { nodeType } = node.getModel();

              const filterTerms = nodeType === query?.parent_id ? query.parent_id : query?.id;

              return (
                filterTerms &&
                (typeIsNot
                  ? (node.getModel().label as string).toLowerCase() !== typeText
                  : typeIs
                  ? (node.getModel().label as string).toLowerCase() === typeText
                  : typeIsNull
                  ? node.getModel().img === ''
                  : typeIsNotNull
                  ? node.getModel().img
                  : (node.getModel().label as string).toLowerCase().includes(typeText))
              );
            });

            filteredNodes.forEach((node) => {
              const { img, color } = node.getModel();
              const styles = updateStylesNodeEdges(query, img as string, color as string);
              graph.updateItem(node.getID(), styles);
              graph.refreshItem(node);
            });
          } else {
            const nodes = graph.getNodes().filter((node) => node.getModel().nodeType === query.id);
            nodes.forEach((node) => {
              const { img, color } = node.getModel();
              const styles = updateStylesNodeEdges(query, img as string, color as string);
              graph.updateItem(node.getID(), styles);
              graph.refreshItem(node);
            });
          }
          const filteredEdges = graph
            .getEdges()
            .filter(
              (edge) =>
                (edge.getModel().label as string).toLowerCase() === query.label ||
                edge.getModel().project_edge_type_id1 === query.id
            );
          filteredEdges.forEach((edge) => {
            graph.updateItem(edge.getID() as string, {
              size: query.size,
              style: {
                stroke: query.color,
                lineWidth: query.borderSize || borderInitSize,
                lineDash: query.borderDashed ? [query.borderSize, 4] : [],
                endArrow: {
                  fill: query.color,
                  path: G6.Arrow.triangle(10, 15, 5),
                  d: 5,
                },
              },
            });
          });
        });

        graph.render();
      }
    },
    [graph, handleAction, project_id]
  );

  useEffect(() => {
    if (data.id) {
      const formatedData = formmatedDataStyles(data);
      form.setFieldsValue({
        operator: data?.operator,
        queries: formatedData,
      });
      form.submit();
      isReset.current = false;
    } else if (!isReset.current) {
      form.resetFields();
      isReset.current = true;
    }
  }, [data, form]);

  return (
    <>
      <Form form={form} name="styling" onFinish={onFinish} style={{ height: '100%' }}>
        <StyledMainWrapper>
          <QueriesForm
            openTable={openTable}
            setOpenTable={setOpenTable}
            isVisualisation={true}
            removeItemQueries={removeItemQueries}
          />
          <Buttons
            setOpenTable={setOpenTable}
            resetFields={removeGraphStyle}
            onHandleToggleModal={onHandleToggleModal}
          />
        </StyledMainWrapper>
      </Form>
    </>
  );
};
