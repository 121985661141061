export const initialState = {
  activeTools: [],
  activeBoard: '',
  boards: [],
  selectedTool: null,
  tools: {},
  toolBarWidth: 370,
  barTypeWidth: 270,
  canvasWidth: window.innerWidth - 670,
  nodeTypeId: '',
};
