/*  
    initial state for the visualization history
*/

import { TInitialState } from './types';

export const initialState: TInitialState = {
  name: undefined,
  openSaveModal: false,
  visualizationId: undefined,
  hoverItem: undefined,
  reset: false,
  queriesStyle: undefined,
};
