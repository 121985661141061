/* 
    Buttons possible text  
*/

export enum ButtonsText {
  SAVE_QUERIES = 'Save Queries',
  SAVE_STYLING = 'Save Styling',
  CLEAN_ALL = 'Clean All',
  RUN = 'Run',
  ADD = 'Add',
}
