import { Button } from 'antd';
import styled from 'styled-components';

export const DotsWrapper = styled(({ fullWidth, ...props }) => <Button type="text" {...props} />)`
  && {
    position: relative;
    height: 30px;
    width: 15px;
    padding: 3px;
    border-radius: 8px;

    &:hover,
    &:active {
      border-color: transparent;
      background-color: rgba(35, 47, 106, 0.1);
    }

    circle {
      fill: #2d356e75;
      font-size: 20px;
    }
  }
`;
