import { Drawer } from 'antd';
import styled from 'styled-components';

export const DrawerWrapper = styled(Drawer)`
  & .ant-drawer-header-title {
    flex-direction: row-reverse;
  }
`;

export const SelectMemebers = styled.div`
  .ant-select-selector {
    height: auto !important;
    padding-top: 0 !important;
  }
`;
