import { useMutation, useQueryClient } from '@tanstack/react-query';
import client from 'api/client';
import { URL_DELETE_VISUALIZATION_BY_ID, URL_SAVED_VISUALIZATION } from './constant';
import { useParams } from 'react-router-dom';

export const useDeleteVisualizationById = () => {
  const { id: projectId } = useParams();

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (id: string) => {
      return client.delete(
        URL_DELETE_VISUALIZATION_BY_ID.replace(':project_id', projectId ?? '').replace(':id', id ?? '')
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries([URL_SAVED_VISUALIZATION.replace(':project_id', projectId ?? '')]);
    },
  });

  return mutation;
};
