import { useState } from 'react';
import { Button } from 'components/button';
import { Modal } from 'components/modal';
import { VerticalSpace } from 'components/space/vertical-space';
import { Text } from 'components/typography';
import { useDeleteMember } from 'api/perspective/shared-users/use-delete-user';
import { useGetAllMembers } from 'api/perspective/shared-users/use-get-all-members';
import { IDeleteSharedUserModal } from 'types/delete-share-user-modal';
import { ErrorResponse } from 'react-router-dom';
import { Flex } from 'antd';

type ExtendedErrorResponse = ErrorResponse & {
  response?: {
    data?: {
      errors?: {
        message: string | null;
      };
    };
  };
};

export const DeleteSharedUserModal = ({ id, setDeleteMembers, isDeleteMembers }: IDeleteSharedUserModal) => {
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [messageText, setErrorMessageText] = useState('');
  const pagination = { page: 1, size: 10, search: '', force: errorModalOpen ? true : false };

  const { data } = useGetAllMembers(pagination);
  const perspectiveId = data?.rows?.find((user) => user?.perspective_user_id === id)?.perspective_id;

  const { mutate: deleteMember } = useDeleteMember(pagination);

  const handleCancel = () => {
    if (setDeleteMembers) {
      setDeleteMembers(false);
    }
  };

  const handleDelete = async () => {
    if (!perspectiveId) {
      setErrorModalOpen(true);
      return;
    }

    const deleteParams = { id: perspectiveId, perspective_user_id: id, force: pagination?.force };
    const callbacks = {
      onSuccess: () => {
        setDeleteMembers?.(false);
        setErrorModalOpen(false);
      },
      onError: (
        error: unknown,
        variables: { id: string; perspective_user_id: string; force: boolean },
        context?: unknown
      ) => {
        const err = error as ExtendedErrorResponse;
        setErrorMessageText(err.response?.data?.errors?.message || '');
        setDeleteMembers?.(false);
        setErrorModalOpen(true);
      },
    };
    deleteMember(deleteParams, callbacks);
  };

  const handleErrorModalClose = () => {
    setErrorModalOpen(false);
  };

  const modalTitle = (
    <Text style={{ textAlign: 'center' }}>
      {errorModalOpen ? (
        <Flex gap={10} vertical>
          <div style={{ color: 'red' }}>{messageText}</div>
          Are you sure you want to delete this member? This action will also remove them from the group.
        </Flex>
      ) : (
        'Are you sure you want to delete this member?'
      )}
    </Text>
  );

  const modalOpen = errorModalOpen || isDeleteMembers;
  const modalCancel = errorModalOpen ? handleErrorModalClose : handleCancel;

  return (
    <>
      <Modal title={modalTitle} open={modalOpen} footer={null} closable={false} className="project-modal">
        <VerticalSpace>
          <Button block onClick={handleDelete} type="primary">
            Delete
          </Button>
          <Button block type="default" onClick={modalCancel}>
            Cancel
          </Button>
        </VerticalSpace>
      </Modal>
    </>
  );
};
