import React from 'react';
import { Tooltip } from 'antd';

import { ReactComponent as FindSVG } from '../icons/find.svg';

export const FitType: React.FC<{ onCenterType: VoidFunction }> = ({ onCenterType }) => {
  return (
    <Tooltip key={1} placement="top" title="Fit Selected Type">
      <FindSVG onClick={onCenterType} />
    </Tooltip>
  );
};
