import { Edges, Nodes } from 'api/visualisation/use-get-data';
import { GraphData, Node, Edge } from '../types';
import {
  EdgeStyleProperties,
  NodeStyleProperties,
} from 'pages/project-visualisation/components/visualisation-history/types';
import { AnyObject } from 'antd/es/_util/type';
import { COLORS } from 'helpers/constants';
import { edgeLabelCfgStyle, nodeLabelCfgStyle, TYPES_NODE } from './constants';

const { IMAGE, CIRCLE } = TYPES_NODE;

interface SavedGraph {
  nodes: Record<string, NodeStyleProperties>;
  edges: Record<string, EdgeStyleProperties>;
}

type FormattedData = (
  nodesList: Nodes,
  edgeList: Edges,
  relationsCounts: { [key: string]: number },
  savedGraph?: SavedGraph
) => GraphData;

type FieldProperty = {
  labels: string[];
  properties: AnyObject;
};

type FormatNodeProperty = {
  typeName: string;
  properties: AnyObject;
  isMain?: boolean;
};

export const formattedData: FormattedData = (nodesList, edgeList, relationsCounts, savedGraph) => {
  if (!relationsCounts) return {} as GraphData;

  const nodes: (Node & { edgeCount?: number })[] = [];

  nodesList.forEach(({ _fields }) => {
    _fields?.forEach((node) => {
      if (!node.hasOwnProperty('low')) {
        const field = node as FieldProperty;

        const [typeName, properties] = [field.labels[0], field.properties || {}];

        let property = { ...properties, isMain: true };

        if (savedGraph) {
          property = { ...properties, ...savedGraph.nodes[field.properties?.id], isMain: false };
        }

        const params = {
          ...formatNodeProperty({ typeName, properties: property }),
          edgeCount: relationsCounts[field.properties?.id] ?? 0,
        };
        nodes.push(params);
      }
    });
  });

  const edges: Edge[] = [];

  edgeList.forEach(({ _fields }) => {
    _fields?.forEach((edge) => {
      const { properties } = edge;

      if (edge) {
        const savedNodeStyles = (savedGraph?.edges?.[properties.id] as EdgeStyleProperties) || {};

        edges.push({
          id: properties.id ?? '',
          project_edge_type_id: properties.project_edge_type_id,
          source: properties.source_id,
          target: properties.target_id,
          labelCfg: edgeLabelCfgStyle,
          label: edge.type ?? '',
          style: {
            stroke: savedNodeStyles.color || COLORS.MAIN_GRAY_SILVER,
            lineWidth: savedNodeStyles.size || 2,
            lineDash: savedNodeStyles.type === 'dashed' ? [5, 5] : undefined,
          },
        });
      }
    });
  });

  return { nodes, edges } as GraphData;
};

const formatNodeProperty = ({ typeName, properties }: FormatNodeProperty) => {
  const {
    created_at = '',
    updated_at = '',
    user_id = '',
    project_type_parent_id = '',
    id = '',
    fx = 0,
    fy = 0,
    color = '',
    default_image = '',
    name = '',
    icon = '',
    project_type_id = '',
    project_id = '',
    size,
    isMain,
    ...params
  } = properties || {};

  const defaultParams = {
    id,
    color,
    originalName: name,
    label: name?.length > 15 ? `${name.slice(0, 15)}...` : name,
    size: size,
    x: fx,
    y: fy,
    nodeType: project_type_id,
    nodeTypeName: typeName,
    style: {
      fill: icon || !default_image ? COLORS.PRIMARY.WHITE : 'transparent',
      stroke: color,
      lineWidth: 2,
      width: size || 40,
      height: size || 40,
    },
    properties: { ...params },
    labelCfg: nodeLabelCfgStyle,
  };

  if (default_image && !icon.length) {
    return {
      type: IMAGE,
      ...defaultParams,
      img: `${process.env.REACT_APP_AWS_URL}${default_image}?key=${Date.now()}`,
      clipCfg: !isMain
        ? {
            show: true,
            type: CIRCLE,
            width: size,
            height: size,
            r: size / 2,
          }
        : undefined,
    };
  } else {
    return {
      type: CIRCLE,
      ...defaultParams,
      icon: {
        show: icon,
        width: size,
        height: size,
        img: icon || '',
      },
    };
  }
};
