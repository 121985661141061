import { Tooltip } from 'antd';
import { StyledButton } from './style';
import { TMainActionsProps } from '../type';

import './action-tooltip.css';

export const MainActionButton = ({ icon, helpText, ...props }: TMainActionsProps) => {
  return (
    <Tooltip overlayClassName="action-tooltip" title={helpText} arrow={false} placement="bottom">
      <StyledButton shape="circle" icon={icon} {...props} />
    </Tooltip>
  );
};
