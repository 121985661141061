import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { URL_GET_VISUALIZATION_STYLES_BY_ID } from './constant';
import client from 'api/client';
import { TSaveVisualizationStyles, TStylesItem } from 'api/types';

export const useGetVisualizationStyles: TSaveVisualizationStyles = (id, options) => {
  const { id: project_id } = useParams();
  const URL = URL_GET_VISUALIZATION_STYLES_BY_ID.replace(':project_id', project_id ?? '').replace(':id', id ?? '');

  const result = useQuery<{ data: TStylesItem }, Error, TStylesItem>(
    [URL],
    () => client.get<{ data: TStylesItem }>(URL).then((response) => response.data),
    options
  );

  const { isLoading, data } = result;

  return {
    isLoading,
    data: data ?? ({} as TStylesItem), // Provide a default value for `data`
  };
};
