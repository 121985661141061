import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import client from 'api/client';
import { URL_COMMENT_NODES_DOMAIN_TYPE, URL_COMMENTS_LIST, URL_COMMENTS_NODES_LIST } from 'api/comments/constants';
import { ApiErrorResponse } from 'api/types';
import { TProjectComment, UpdateDomainTypeResponse } from 'types/project';

export const useUpdateDomainTypeNode = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async ({ id, data }: { id: string; data: TProjectComment }): Promise<UpdateDomainTypeResponse> => {
      return client.put(URL_COMMENT_NODES_DOMAIN_TYPE.replace(':id', id), data);
    },
    onSuccess: (data: UpdateDomainTypeResponse, variables, context) => {
      message.success(data.message);
      queryClient.invalidateQueries([URL_COMMENTS_LIST.replace(':project_id', data?.data?.project_id ?? '')]);
      queryClient.invalidateQueries([URL_COMMENTS_NODES_LIST.replace(':node_id', data?.data?.node_id ?? '')]);
    },
    onError: (error: ApiErrorResponse) => {
      const errorMessage = error.response?.data.errors?.message;
      message.error(errorMessage);
    },
  });

  return mutation;
};
