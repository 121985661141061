import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { URL_GET_VISUALIZATION_BY_ID } from './constant';
import client from 'api/client';
import { TSaveVisualizationData } from 'api/types';

export type TUseAllStyles = (
  id: string | undefined,
  options?: UseQueryOptions<{ data: TSaveVisualizationData }, Error>
) => {
  data: TSaveVisualizationData | undefined;
  isLoading: boolean;
  isSuccess: boolean;
};

export const useGetAllVisualizationById: TUseAllStyles = (id, options) => {
  const { id: projectId } = useParams();

  const URL = URL_GET_VISUALIZATION_BY_ID.replace(':project_id', projectId ?? '').replace(':id', id ?? '');

  const result = useQuery<{ data: TSaveVisualizationData }, Error>([URL], () => client.get(URL), options);

  const { isLoading, data, isSuccess } = result;

  return {
    isLoading,
    data: isSuccess ? data?.data : undefined,
    isSuccess,
  };
};
