import { Flex, List } from 'antd';
import styled, { keyframes } from 'styled-components';

export const animationIcon = keyframes`
  from {
    opacity: 0;
    transform: translateX(+10px);
      overflow: hidden;
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;
export const AnimatedDeleteIcon = styled(Flex)`
  cursor: pointer;
  opacity: 0;
  animation: ${animationIcon} 0.1s ease forwards;
`;

export const ListMeta = styled(List.Item.Meta)`
  .ant-list-item-meta-content {
    flex-basis: max-content !important;
  }

  .ant-list-item-meta-description {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
  }
`;
export const ListItem = styled(List.Item)`
  overflow: hidden;
  .ant-select-selection-item {
    flex: none;
  }
`;

export const ShareUser = styled.div`
  && {
    .ant-select-selector {
      border-color: white !important;
      background: linear-gradient(91.54deg, rgba(232, 235, 248, 0.7) 5.25%, rgba(232, 235, 248, 0.2) 97.48%);
      align-items: center;
      min-width: 134px;
      height: 40px;
    }
  }
`;
