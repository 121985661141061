import React from 'react';
import { useGraphRef } from 'hooks/use-graph';
import { Wrapper } from './wrapper';
import { useGetData } from 'api/visualisation/use-get-data';
import { Spin } from 'antd';
import { useGraph } from 'components/layouts/components/visualisation/wrapper';

export const Graph: React.FC = () => {
  const { graphInfo } = useGraph() ?? {};
  const { nodes, edges, count, relationsCounts, isInitialLoading } = useGetData();
  const ref = useGraphRef({ nodes, edges, count, relationsCounts });

  return (
    <Spin style={{ maxHeight: '100%' }} spinning={isInitialLoading}>
      <Wrapper isExpanded={graphInfo?.collapsedWidth} ref={ref} />
    </Spin>
  );
};
